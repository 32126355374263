<script lang="ts" setup>
import { Vue3Lottie } from 'vue3-lottie'
import SuperPowerAniamtion from "@/assets/burst.json";

const target = ref();
const animate = ref(false);
if (process.client) {
	const observer = new IntersectionObserver(
			([entry]) => {
					animate.value = entry.isIntersecting;
					console.log("animation", animate.value)
			},
			{
					threshold: .1, 
			}
	);
	onMounted(() => {
			observer.observe(target.value);
	});
}

</script>

<template>
    <div class="max-w-screen-lg md:py-6 mt-20 mx-auto px-4" ref="target">
        <div class="rounded-2xl bg-primary-500 drop-shadow-2xl px-2 gap-8 items-center overflow-x-hidden lg:overflow-visible">
            <div class="grid grid-cols-1 md:grid-cols-2 px-10 items-center md:h-[440px]">
                <div class="mt-10 mb-8">
                    <p class="text-secondary-500">{{$t('home.features.superpower.title')}}</p>
                    <h3 class="text-4xl pr-4  md:mt-0 font-bold font-owners text-white">{{$t('home.features.superpower.headline')}}</h3>
                </div>
                <div class="relative -translate-y-10 md:translate-y-0 h-60">
                    <div class="h-60 w-60 absolute center-stack">
											<ClientOnly> 
                        <Vue3Lottie
                            ref="controller"
                            alt="Superpower star burst animation"
                            class="relative scale-[2.5] translate-y-3"       
                            :loop="true"
                            :auto-play="false"
                            :pause-animation="!animate"
                            :animationData="SuperPowerAniamtion" 
                            />
											 </ClientOnly> 
                    </div>
                    
                    <img alt="Smoxy superpower star"
                        src="@/assets/icon_superpower.svg"
                        class="w-38 h-38 absolute center-stack"
                        width="240" height="240"
                        />  
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mx-10 text-white/80 -translate-y-10">
                <p>{{$t('home.features.superpower.body1')}}</p>
                <p>{{$t('home.features.superpower.body2')}}</p>
            </div>
        </div>
    </div>    
</template>